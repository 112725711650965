<div class="card p-2 mr-1 shadow-sm">


  <div class="row flex-row align-items-center ">
    <div *ngIf="showThumbnails" class="col-3 col-md-2 ">
      <a *ngIf="linkType !== linkTypes.None" [target]="(linkType === linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType === linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]" class="dont-break-out">
        <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
        </ds-thumbnail>
      </a>
      <span *ngIf="linkType === linkTypes.None" class="dont-break-out">
        <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
        </ds-thumbnail>
      </span>
    </div>
    <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'" class="">
      <div class="d-flex">
        <ds-badges *ngIf="showLabel" [object]="dso" [context]="context" [showAccessStatus]="true"></ds-badges>

      </div>

      <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
        <a *ngIf="linkType !== linkTypes.None" [target]="(linkType === linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType === linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]" class="item-list-title dont-break-out font-weight-bold" [innerHTML]="dsoTitle"></a>
        <span *ngIf="linkType === linkTypes.None" class="lead item-list-title dont-break-out" [innerHTML]="dsoTitle"></span>
        <span class="text-muted">
          <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
              (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher" [innerHTML]="firstMetadataValue('dc.publisher')"></span>
              <span *ngIf="dso.firstMetadataValue('dc.publisher') && dso.firstMetadataValue('dc.date.issued')">, </span>
              <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
            </ng-container>
            <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0" class="item-list-authors">
              <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                <span *ngIf="!last">; </span>
              </span>
            </span>
          </ds-truncatable-part>
        </span>
        <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
          <ds-truncatable-part [id]="dso.id" [minLines]="3"><span [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
          </ds-truncatable-part>
        </div>
      </ds-truncatable>
    </div>

  </div>
  <div class="d-flex justify-content-end">
    <div id="stats-box">
      <ng-container *ngIf="itemStatistics$ | async as stats">
        <span>{{ stats.views }} <i class="fa-regular fa-eye"></i></span>
        <span>{{ stats.downloads }} <i class="fa fa-download"></i></span>
      </ng-container>

    </div>

  </div>
</div>