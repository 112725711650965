<nav class="navbar navbar-dark p-0 vh-100" id="admin-sidebar" [attr.aria-label]="'menu.header.nav.description' | translate" [ngClass]="{'expanded': sidebarOpen, 'collapsed': sidebarClosed, 'transitioning': sidebarTransitioning}" [@slideSidebar]="{
      value: ((sidebarExpanded | async) !== true ? 'collapsed' : 'expanded'),
      params: { collapsedWidth: (collapsedSidebarWidth$ | async), expandedWidth: (expandedSidebarWidth$ | async) }
     }" (@slideSidebar.done)="finishSlide($event)" (@slideSidebar.start)="startSlide($event)" *ngIf="menuVisible | async" (mouseenter)="handleMouseEnter($event)" (mouseleave)="handleMouseLeave($event)">

  <!-- HEADER -->

  <div class="sidebar-full-width-container" id="sidebar-header-container" aria-hidden="true">
    <div class="sidebar-section-wrapper">
      <div class="sidebar-fixed-element-wrapper">
        <img id="admin-sidebar-logo" src="assets/images/SDL-LOGO.png" [alt]="('menu.header.image.logo') | translate" aria-hidden="true">
      </div>
      <div class="sidebar-collapsible-element-outer-wrapper">
        <div class="sidebar-collapsible-element-inner-wrapper sidebar-item">
          <h4 class="my-1">{{ 'menu.header.admin' | translate }}</h4>
        </div>
      </div>
    </div>
  </div>

  <!-- ITEMS -->

  <div class="sidebar-full-width-container" id="sidebar-top-level-items-container">
    <div class="sidebar-full-width-container" id="sidebar-top-level-items" role="menubar" [attr.aria-label]="'menu.header.admin.description' |translate">
      <ng-container *ngFor="let section of (sections | async)">
        <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
      </ng-container>
    </div>
  </div>

  <!-- TOGGLER -->

  <div class="sidebar-full-width-container" id="sidebar-collapse-toggle-container">
    <a class="sidebar-section-wrapper sidebar-full-width-container" id="sidebar-collapse-toggle" href="javascript:void(0);" (click)="toggle($event)" (keyup.space)="toggle($event)">
      <div class="sidebar-fixed-element-wrapper">
        <i *ngIf="(menuCollapsed | async)" class="fas fa-fw fa-angle-double-right" [title]="'menu.section.icon.pin' | translate"></i>
        <i *ngIf="(menuCollapsed | async) !== true" class="fas fa-fw fa-angle-double-left" [title]="'menu.section.icon.unpin' | translate"></i>
      </div>
      <div class="sidebar-collapsible-element-outer-wrapper">
        <div class="sidebar-collapsible-element-inner-wrapper sidebar-item">
          {{ ((menuCollapsed | async) ? 'menu.section.pin' : 'menu.section.unpin' ) | translate }}
        </div>
      </div>
    </a>
  </div>

</nav>