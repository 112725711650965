<ng-container *ngIf="(sources$ | async)?.length > 0">
  <ng-container *ngFor="let source of sources$ | async">
    <div class="alert alert-info d-flex flex-row" *ngIf="source.totalEvents > 0">
      <div class="source-logo-container">
        <img class="source-logo" src="assets/images/qa-{{ source.id }}-logo.png" onerror="this.src='assets/images/SDL-LOGO.png'" alt="{{ source.id }} logo" />
      </div>
      <div class="w-100 d-flex justify-content-between">
        <div class="pl-4 align-self-center">
          {{ "mydspace.qa-event-notification.check.notification-info" | translate : { num: source.totalEvents } }}
        </div>
        <button [routerLink]="[getQualityAssuranceRoute(), source.id]" [queryParams]="{ forward: true }" class="btn btn-primary align-self-center">
          {{ "mydspace.qa-event-notification-info.check.button" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>