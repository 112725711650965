import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

function isRtl(): boolean {
  const dsLanguage = getCookie('dsLanguage');
  return dsLanguage === 'ar';
}

export const pushInOut = trigger('pushInOut', [

  /*
  state('expanded', style({ right: '100%' }));

  state('collapsed', style({ right: 0 }));
*/

  // state('expanded', style({ left: '100%' })),

  // state('collapsed', style({ left: 0 })),

  state('expanded', style((isRtl()) ?
    { right: '100%' } :
    { left: '100%' }
  )),
  state('collapsed', style((isRtl()) ?
    { right: '0' } :
    { left: '0' }
  )),

  transition('expanded <=> collapsed', animate(250)),
]);
