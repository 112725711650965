
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from 'src/config/app-config.interface';
import { Item } from '../../../../../../core/shared/item.model';
import { ViewMode } from '../../../../../../core/shared/view-mode.model';
import { getItemPageRoute } from '../../../../../../item-page/item-page-routing-paths';
import { ThemedThumbnailComponent } from '../../../../../../thumbnail/themed-thumbnail.component';
import { ThemedBadgesComponent } from '../../../../../object-collection/shared/badges/themed-badges.component';
import { ItemSearchResult } from '../../../../../object-collection/shared/item-search-result.model';
import { listableObjectComponent } from '../../../../../object-collection/shared/listable-object/listable-object.decorator';
import { TruncatableComponent } from '../../../../../truncatable/truncatable.component';
import { TruncatablePartComponent } from '../../../../../truncatable/truncatable-part/truncatable-part.component';
import { SearchResultListElementComponent } from '../../../search-result-list-element.component';
import { UsageReportDataService } from '../../../../../../core/statistics/usage-report-data.service';
import { UsageReport } from '../../../../../../core/statistics/models/usage-report.model';
import { DSONameService } from '../../../../../../core/breadcrumbs/dso-name.service';
import { TruncatableService } from '../../../../../truncatable/truncatable.service';


@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement)
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement)
@Component({
  selector: 'ds-item-search-result-list-element',
  styleUrls: ['./item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html',
  standalone: true,
  imports: [NgIf, RouterLink, ThemedThumbnailComponent, NgClass, ThemedBadgesComponent, TruncatableComponent, TruncatablePartComponent, NgFor, AsyncPipe],
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends SearchResultListElementComponent<ItemSearchResult, Item> implements OnInit {
  /**
   * Route to the item's page
   */
  itemPageRoute: string;

  itemViews$: Observable<number>;
  isLoadingViews = true;
  errorLoadingViews = false;


  // New property for item statistics (e.g., views)
  itemStatistics$: Observable<any>;

  constructor(
    protected truncatableService: TruncatableService,
    dsoNameService: DSONameService,
    protected usageReportService: UsageReportDataService,
    @Inject(APP_CONFIG) protected appConfig: AppConfig
  ) {
    super(truncatableService, dsoNameService, appConfig);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.showThumbnails = this.showThumbnails ?? this.appConfig.browseBy.showThumbnails;
    this.itemPageRoute = getItemPageRoute(this.dso);

    // Fetch multiple statistics for this item
    this.itemStatistics$ = forkJoin({
      views: this.usageReportService.getStatistic(this.dso.id, 'TotalVisits'),
      downloads: this.usageReportService.getStatistic(this.dso.id, 'TotalDownloads'),
      // Add more statistics as needed
    }).pipe(
      map(stats => ({
        views: this.extractValue(stats.views, 'views'),
        downloads: this.extractValue(stats.downloads, 'downloads'),
        // Extract more statistics as needed
      }))
    );
  }
  private extractValue(report: UsageReport, key: string): number {
    if (report && report.points && report.points.length > 0) {
      return report.points[0].values[key] || 0;
    }
    return 0;
  }
}
